// main: main.less
/* ==========================================================================
Media Queries
========================================================================== */

@media only screen and (max-width: @screen-md-max) {
    #menu .navbar-nav>li>a {
        padding: 13px 9px;
    }
    .licznik {
        padding-left: 3px;
    }
    .box-turkus .header {
        font-size: 38px;
    }
    #bg #bg2 .para-container img.pani {
        position: absolute;
        bottom: -30px;
        right: -90px;
    }
}
@media only screen and (max-width: @screen-sm-max) {
    .licznik {
        padding-left: 0px;
    }
    .licznik div.el {
        margin-right: 6px;
    }
    
    #bg #bg2 .para-container img.ikony {
        position: absolute;
        top: 200px;
        left: 59px;
        max-width: 300px;
    }
    #bg #bg2 .para-container img.header {
        position: absolute;
        top: 57px;
        left: 40px;
        max-width: 355px;
    }
    #bg #bg2 .para-container img.pani {
        position: absolute;
        bottom: -61px;
        right: -134px;
    }
}

@media only screen and (max-width: @screen-xs-max) {
    #menu .nav>li {
        border-left: 0;
    }
    #menu .nav>li:last-of-type {
        border-right: 0;
    }
    .navbar-collapse.in {
        overflow: hidden;
    }
    li.dropdown.open {
        border: 1px solid #a5d244;
        border-left: 1px solid #a5d244 !important;
    }
    .licznik {
        width: 250px;
        margin: 0 auto;
    }
    #bg #bg2 .para-container {
        padding: 50px 40px;
        height:200px;
    }
    #bg #bg2 .para-container img.header,
    #bg #bg2 .para-container img.ikony {
        position: initial;
        .img-responsive;
    }
    #bg {
        height: 200px;
        overflow: hidden;
    }
    #bg #bg2 {
        height: 250px;
    }
    #bg #bg2 .para-container {
        position: relative;
        height: 200px;
        max-width: none;
    }
    #bg #bg2 .para-container img.ikony {
        margin-top: 25px;
    }
}

@media print,
    (-o-min-device-pixel-ratio: 5/4),
    (-webkit-min-device-pixel-ratio: 1.25),
    (min-resolution: 120dpi) {

    }